import PublicLayout from "@layouts/public/components/PublicLayout";
import { GetStaticPathsContext, GetStaticPropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { CmsLayout } from "@modules/cms/types/CmsLayout";
import CmsLayouts from "@modules/cms/components/CmsLayouts";
import { ReactElement } from "react";
import SeoInfoProps from "@common/types/SeoInfoProps";
import getCmsPageData from "@modules/cms/api/getCmsPageData";
import SeoInfo from "@common/components/SeoInfo";
import { useRouter } from "next/router";
import NotFound from "@pages/404";
import getCanonicalUrl from "@common/helpers/getCanonicalUrl";
import getCmsPages from "@modules/cms/api/getCmsPages";
import shouldBuildCmsPage from "@modules/cms/helpers/shouldBuildCmsPage";

type CmsPageProps = {
  cmsLayouts: CmsLayout[];
  cmsSeoInfo: SeoInfoProps | null;
};

const CmsPage = ({ cmsLayouts, cmsSeoInfo }: CmsPageProps) => {
  const router = useRouter();
  if (
    !router.isFallback &&
    (!cmsLayouts || (cmsLayouts && cmsLayouts.length === 0))
  ) {
    return <NotFound />;
  }

  return (
    cmsLayouts &&
    cmsLayouts.length > 0 && (
      <>
        {cmsSeoInfo && <SeoInfo seoInfo={cmsSeoInfo} />}
        <div className="pt-[.1rem]">
          <CmsLayouts layouts={cmsLayouts} />
        </div>
      </>
    )
  );
};

CmsPage.getLayout = function getLayout(page: ReactElement) {
  return <PublicLayout>{page}</PublicLayout>;
};

export default CmsPage;

export async function getStaticProps(context: GetStaticPropsContext) {
  const language = context.locale?.toUpperCase() as string;
  const isPreview = context.preview ?? false;
  const path = (context.params?.cmsPage as string[])
    .toString()
    .replaceAll(",", "/");

  // sometimes internal files in _next/static are found to be built as pages. For example,
  // request "/_next/static/something", and then a html and a json file are generated
  // in ".next/server/pages/en/_next/static/something"
  if (path.startsWith("_next")) {
    return {
      notFound: true,
    };
  }

  const lowerCasePath = path.toLowerCase();
  if (path !== lowerCasePath) {
    return {
      redirect: {
        destination: `/${lowerCasePath}`,
        permanent: true,
      },
    };
  }

  const { seoInfo, layouts } = await getCmsPageData(path, isPreview, language);

  const isAllowedPage = shouldBuildCmsPage(path);

  if (seoInfo && !seoInfo.canonical) {
    seoInfo.canonical = getCanonicalUrl(lowerCasePath);
  }

  return {
    props: {
      cmsLayouts: isAllowedPage ? layouts : [],
      cmsSeoInfo: seoInfo,
      pagePath: lowerCasePath,
      ...(await serverSideTranslations(context.locale ?? "en", ["common"])),
    },
  };
}

CmsPage.pageName = "CmsPage";

export async function getStaticPaths(context: GetStaticPathsContext) {
  if (process.env.SKIP_SSG) {
    return {
      paths: [],
      fallback: "blocking",
    };
  }

  let pages = await getCmsPages();

  pages = pages.filter((page) => shouldBuildCmsPage(page.uri));

  const paths = pages?.map(({ uri }) => {
    const path = uri.split("/").filter((slug) => slug.trim() !== "");
    return {
      params: {
        cmsPage: path,
      },
    };
  });

  return {
    paths: [
      ...paths.flatMap((path) => {
        return context?.locales?.map((locale) => {
          return {
            ...path,
            locale,
          };
        });
      }),
    ],
    fallback: "blocking",
  };
}
