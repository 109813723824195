import Button from "@common/components/Button";
import { VLP_PATH } from "@common/helpers/isVehicleListingPage";
import { Trans, useTranslation } from "next-i18next";
import Link from "next/link";
import LocalImage from "@common/components/LocalImage";
import Image404 from "@public/images/404.svg";
import { ReactElement, useEffect } from "react";
import PublicLayout from "@layouts/public/components/PublicLayout";
import { GetStaticPropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { gtmPushData } from "@common/helpers/gtm";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";

const NotFound = () => {
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    gtmPushData({
      event: "404 Page Not Found",
      path: window.location.href,
    });
    hotjar.event(HotjarEvent.PageNotFound);
  }, []);

  return (
    <div className="bg-white py-10 h-full">
      <div className="max-w-page mx-auto bg-white">
        <div className="flex flex-col-reverse md:flex-row justify-center md:justify-between items-center gap-8 px-4 md:px-20 xl:px-0">
          <div className="md:w-1/2 md:pr-10 lg:pr-20 text-center md:text-left">
            <Trans t={t} i18nKey="common:not_found_title">
              <h1 className="md:pt-10 pb-4">
                <div className="inline text-primary-bold" />
              </h1>
            </Trans>
            <p className="body-1 pb-4">{t("common:not_found_description")}</p>
            <Link href={`/${VLP_PATH}`}>
              <Button buttonStyle="primary">
                {t("common:browse_inventory")}
              </Button>
            </Link>
          </div>
          <div className="md:w-1/2 mb-4">
            <LocalImage src={Image404} alt="Page not found image" priority />
          </div>
        </div>
      </div>
    </div>
  );
};

NotFound.pageName = "NotFound";
NotFound.getLayout = function getLayout(page: ReactElement) {
  return <PublicLayout>{page}</PublicLayout>;
};

export async function getStaticProps(context: GetStaticPropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? "en", ["common"])),
    },
  };
}

export default NotFound;
